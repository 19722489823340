import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/dot-pattern.svg";
import imgHero2 from "../images/hero2.jpg";
import { NavLink } from "./headers/light.js";
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.js";
import { Container } from "./misc/Layouts.js";

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`m-2`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block w-full tracking-wide text-center py-5 m-2`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 `;
const ImageContainer = tw.div`relative  transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-full rounded-t sm:rounded relative`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none  absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export default ({ imageDecoratorBlob = true, buttonRounded = true }) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const [t] = useTranslation();
  return (
    <Container>
      <Row>
        <ImageColumn>
          <ImageContainer>
            <Image src={imgHero2} />
            {imageDecoratorBlob && <ImageDecoratorBlob />}
          </ImageContainer>
        </ImageColumn>
        <TextColumn>
          <PrimaryButton as="a" href="company-contact" css={buttonRoundedCss}>
            Personalanfrage
          </PrimaryButton>
          <PrimaryButton
            as="a"
            href="our-customer-service"
            css={buttonRoundedCss}
          >
            {t("nav.ourCustomerService")}
          </PrimaryButton>
          <PrimaryButton as="a" href="company-faqs" css={buttonRoundedCss}>
            {t("nav.company-faqs")}
          </PrimaryButton>
        </TextColumn>
      </Row>
    </Container>
  );
};
