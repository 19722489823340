import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons";
import Hero2 from "../components/Hero2";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col mb-8 items-center`;
const Container = tw.div`p-8 lg:w-3/4 w-full `;
const Section = tw.div`mb-4`;
const Paragraph = tw.p`text-gray-700 p-2`;

const buttonRoundedCss = tw`rounded-full`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block lg:w-1/3 w-full tracking-wide text-center py-5`;
export default () => (
  <AnimationRevealPage>
    <Nav />
    <Helmet>
      <title>Dienstleistungen - GermanyJobsNow</title>

      <meta name="description" content="" />
      <meta
        name="keywords"
        content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
      />
    </Helmet>
    <Hero2 />
    <HeadingInfoContainer>
      <HeadingTitle>Dienstleistungen</HeadingTitle>
    </HeadingInfoContainer>
    <Content>
      <Container>
        <Section>
          <Paragraph>
            Unsere Dienstleistungen: Fachkräfte und Auszubildende aus dem
            Ausland vermitteln – Fair und Transparent
          </Paragraph>
          <Paragraph>
            Herzlich willkommen bei unserem spezialisierten Service, der darauf
            ausgerichtet ist, Ihrem Unternehmen hochqualifizierte Fachkräfte und
            vielversprechende Auszubildende aus dem Ausland zuzuführen. Unser
            Ansatz basiert auf Fairness, Transparenz und einer
            Win-Win-Strategie, um eine erfolgreiche Zusammenarbeit für alle
            Beteiligten zu gewährleisten.
          </Paragraph>
          <Paragraph>Fachkräftevermittlung:</Paragraph>
          <Paragraph>
            Unsere Fachkräftevermittlung konzentriert sich darauf, die besten
            Talente aus verschiedenen Ländern zu identifizieren und für Ihr
            Unternehmen zu gewinnen. Unsere Vermittlungsgebühren sind fair und
            transparent, ohne versteckte Kosten, um sicherzustellen, dass Ihre
            Investition in hochqualifizierte Fachkräfte klar und nachvollziehbar
            ist.
          </Paragraph>
          <Paragraph>Ausbildungsprogramme:</Paragraph>
          <Paragraph>
            Unsere maßgeschneiderten Ausbildungsprogramme sind darauf
            ausgerichtet, nicht nur fachliche Kenntnisse zu vermitteln, sondern
            auch die persönliche und berufliche Entwicklung der Auszubildenden
            zu fördern. Die Kosten für diese Programme werden transparent
            kommuniziert, um eine klare Grundlage für eine langfristige
            Partnerschaft zu schaffen.
          </Paragraph>
          <Paragraph>Visa- und Einwanderungsunterstützung:</Paragraph>
          <Paragraph>
            Unser erfahrenes Team übernimmt alle erforderlichen Schritte, um
            einen reibungslosen Übergang für die vermittelten Fachkräfte und
            Auszubildenden zu gewährleisten. Unsere Gebühren decken auch die
            Kosten für Visa- und Einwanderungsunterstützung ab, um Ihnen einen
            umfassenden Service zu bieten.
          </Paragraph>
          <Paragraph>Kulturelle Integration:</Paragraph>
          <Paragraph>
            Die kulturelle Integration ist uns wichtig. Unsere Dienstleistungen
            zur Unterstützung der kulturellen Integration sind Teil unseres
            ganzheitlichen Ansatzes, um sicherzustellen, dass die Zusammenarbeit
            für alle Beteiligten fruchtbar und positiv ist.
          </Paragraph>
          <Paragraph>Fairness als Grundprinzip:</Paragraph>
          <Paragraph>
            Unsere Vermittlungsgebühren basieren auf einem fairen und
            transparenten Modell. Wir glauben daran, dass eine erfolgreiche
            Zusammenarbeit auf gegenseitigem Vertrauen und fairen Konditionen
            aufbaut.
          </Paragraph>
          <PrimaryButton as="a" href="company-contact" css={buttonRoundedCss}>
            Kontaktieren Sie uns
          </PrimaryButton>
          <Paragraph>
            Für weitere Informationen über unsere Dienstleistungen und
            Gebührenstruktur stehen wir Ihnen gerne zur Verfügung. Kontaktieren
            Sie unser Team für eine individuelle Beratung, um Ihre spezifischen
            Anforderungen zu besprechen.
          </Paragraph>
          <Paragraph>
            Ihr Erfolg ist unser gemeinsames Ziel – Wir setzen auf Fairness für
            eine langfristige Partnerschaft!
          </Paragraph>
        </Section>
      </Container>
    </Content>

    <Footer />
  </AnimationRevealPage>
);
