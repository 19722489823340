import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import Faqs2 from "../components/Faqs2";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col mb-8 items-center`;

export default () => (
  <AnimationRevealPage>
    <Nav />
    <Helmet>
      <title>FAQ – Für Unternehmen - GermanyJobsNow</title>

      <meta name="description" content="" />
      <meta
        name="keywords"
        content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
      />
    </Helmet>

    <HeadingInfoContainer>
      <HeadingTitle>FAQ – Für Unternehmen</HeadingTitle>
    </HeadingInfoContainer>
    <Content>
      <Faqs2 />
    </Content>

    <Footer />
  </AnimationRevealPage>
);
