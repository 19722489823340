import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import { SectionDescription } from "../components/misc/Typography.js";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col items-center mb-8`;
const Description = tw(
  SectionDescription
)`mt-4 mb-4 text-lg text-gray-700 max-w-lg`;

export default () => (
  <AnimationRevealPage>
    <Nav />
    <HeadingInfoContainer>
      <HeadingTitle>Impressum</HeadingTitle>
    </HeadingInfoContainer>

    <Content>
      <Description>
        <p>Angaben gemäß § 5 TMG</p>
        <p>Hamzah Wadjinny</p>
        <p>Warmuthsreut 16</p>
        <p>95511 Mistelbach</p>
        <p>Vertreten durch: Hamzah Wadjinny</p>
        <p>Telefon: +4992019173779</p>
        <p>E-Mail: contact@germanyjobsnow.com</p>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
          45278091567
        </p>
        <p>Umsatzsteuer-ID:208/284/00607</p>
        <p>Stadt Bayreuth /Finanzamt</p>
      </Description>
    </Content>

    <Footer />
  </AnimationRevealPage>
);
