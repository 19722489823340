import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'react-i18next';
import { SectionHeading } from './misc/Headings.js';
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from './misc/Layouts';
import { SectionDescription } from './misc/Typography';

const Container = tw(
  ContainerBase
)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Description = tw(
  SectionDescription
)`text-gray-400 text-center mx-auto max-w-screen-md`;
const Description2 = tw(
  SectionDescription
)`text-gray-400 text-left mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

export default () => {
  const [t] = useTranslation();

  const stats = [
    {
      key: t('featureStats.stats.jobs'),
      value: '+686 510',
    },
    {
      key: t('featureStats.stats.trainings'),
      value: '+527 433',
    },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>{t('featureStats.heading')}</Heading>
          <Description>{t('featureStats.description')}</Description>
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
        <Description2>{t('featureStats.desc1')}</Description2>
        <Description2>{t('featureStats.desc2')}</Description2>
        <Description2>{t('featureStats.desc3')}</Description2>
      </ContentWithPaddingXl>
    </Container>
  );
};
