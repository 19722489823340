/* eslint-disable class-methods-use-this */
import http from "./HttpCommon";

// function authHeader() {
//   const userToken = JSON.parse(localStorage.getItem('userToken'));

//   if (userToken) {
//     return { Authorization: `Bearer ${userToken.data}` };
//   }
//   return {};
// }

class Api {
  async AddClient(data) {
    return http.post(`/clients/`, data).then((response) => response.data);
  }

  async AddCompany(data) {
    return http.post(`/companies/`, data).then((response) => response.data);
  }

  async getCountries() {
    return http
      .get(`/countries/all/orderByName`)
      .then((response) => response.data);
  }

  async getOffersByReason(reason) {
    return http
      .get(`/offers/reasonToGo/${reason}`)
      .then((response) => response.data);
  }
}

export default new Api();
