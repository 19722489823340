/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgDecoratorBlob3 } from '../images/svg-decorator-blob-3.svg';
import Happy from '../images/happy.svg';
import Brain from '../images/brain.svg';
import Scholarship from '../images/scholarship.svg';
import MoneyEuro from '../images/money-euro.svg';
import { SectionDescription } from './misc/Typography.js';
import { SectionHeading } from './misc/Headings.js';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw` font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null }) => {
  const [t] = useTranslation();

  const defaultCards = [
    {
      imageSrc: Happy,
      description: t('advantages.adv1'),
    },
    { imageSrc: Brain, description: t('advantages.adv2') },
    { imageSrc: MoneyEuro, description: t('advantages.adv3') },
    { imageSrc: Scholarship, description: t('advantages.adv4') },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{t('advantages.heading')}</Heading>
        <Description>{t('advantages.desc')}</Description>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img alt="" src={card.imageSrc} />
              </span>
              <span className="textContainer">
                <p className="description">{card.description || '-'}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
