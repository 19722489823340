/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Api from "../Api";

const Error = tw.span`text-red-500 text-sm ml-2`;
const FormController = tw.div`grid  grid-cols-1 gap-4`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-screen-md`;
const Input = tw.input`w-full px-3 py-3 mb-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const TextArea = tw.textarea`w-full px-3 py-3 mb-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 mb-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const CheckBox = tw.input`px-3 py-3 mb-2 font-medium text-sm mt-5 mx-3 ml-0`;
const SuccessAlert = tw.div`bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-3 rounded-lg`;
const ErrorAlert = tw.div`bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-3 rounded-lg`;
const Bold = tw.p`font-bold`;

export default () => {
  const [t] = useTranslation();

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(0);

  const [errors, setErrors] = useState({
    companyName: undefined,
    industry: undefined,
    location: undefined,
    positionDescription: undefined,
    wantedExperience: undefined,
    wantedLanguage: undefined,
    otherRequirements: undefined,
    startTime: undefined,
    contractDuration: undefined,
    contactName: undefined,
    contactEmail: undefined,
    contactPhone: undefined,
    terms: undefined,
  });
  // console.log('errors');
  // console.log(errors);

  const [company, setCompany] = useState({
    companyName: "",
    industry: "",
    location: "",
    positionDescription: "",
    wantedExperience: "",
    wantedLanguage: "",
    otherRequirements: "",
    startTime: "",
    contractDuration: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    terms: false,
  });

  // console.log('company');
  // console.log(company);

  const schema = Yup.object().shape({
    companyName: Yup.string().required(),
    industry: Yup.string().required(),
    location: Yup.string().required(),
    positionDescription: Yup.string().required(),
    wantedExperience: Yup.string().required(),
    wantedLanguage: Yup.string().required(),
    otherRequirements: Yup.string().required(),
    startTime: Yup.string().required(),
    contractDuration: Yup.string().required(),
    contactName: Yup.string().required(),
    contactEmail: Yup.string().email().required(),
    contactPhone: Yup.string().required(),
    terms: Yup.boolean().oneOf([true], t("apply.mustAccept")),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setLoading(1);
    setSuccessMsg("");
    setErrorMsg("");

    // console.log("company");
    // console.log(company);

    schema.isValid(company).then(async (valid) => {
      if (valid) {
        try {
          setLoading(2);
          Api.AddCompany(company).then(
            () => {
              setSuccessMsg(
                "Vielen Dank, wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen"
              );
              setLoading(3);
            },
            (error) => {
              // console.log(error);

              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              setErrorMsg(resMessage);
              setLoading(0);
              setSubmitting(false);
              // console.log(resMessage);
            }
          );
        } catch (error) {
          // console.log('Error ', error);
          setErrorMsg("Unknown error, try again or contact support");
        }
      } else {
        // console.log('Invalid form');
        setErrorMsg(t("apply.invalidForm"));
        setLoading(0);
        setSubmitting(false);
      }
    });
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <FormController>
          <label>
            Name des Unternehmens
            <Error>
              {errors.companyName === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.companyName}
              onChange={(e) => {
                setCompany({ ...company, companyName: e.target.value });
                setErrors({
                  ...errors,
                  companyName: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, companyName: e.target.value });
                setErrors({
                  ...errors,
                  companyName: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Branche
            <Error>
              {errors.industry === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.industry}
              onChange={(e) => {
                setCompany({ ...company, industry: e.target.value });
                setErrors({
                  ...errors,
                  industry: Yup.string().required().isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, industry: e.target.value });
                setErrors({
                  ...errors,
                  industry: Yup.string().required().isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Standort des Unternehmens
            <Error>
              {errors.location === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.location}
              onChange={(e) => {
                setCompany({ ...company, location: e.target.value });
                setErrors({
                  ...errors,
                  location: Yup.string().required().isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, location: e.target.value });
                setErrors({
                  ...errors,
                  location: Yup.string().required().isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Beschreibung der zu besetzenden Stelle
            <Error>
              {errors.positionDescription === false
                ? t("apply.invalidField")
                : ""}
            </Error>
            <TextArea
              rows={3}
              type="text"
              value={company.positionDescription}
              onChange={(e) => {
                setCompany({ ...company, positionDescription: e.target.value });
                setErrors({
                  ...errors,
                  positionDescription: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, positionDescription: e.target.value });
                setErrors({
                  ...errors,
                  positionDescription: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Gewünschte Qualifikationen und Erfahrungen
            <Error>
              {errors.wantedExperience === false ? t("apply.invalidField") : ""}
            </Error>
            <TextArea
              rows={3}
              type="text"
              value={company.wantedExperience}
              onChange={(e) => {
                setCompany({ ...company, wantedExperience: e.target.value });
                setErrors({
                  ...errors,
                  wantedExperience: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, wantedExperience: e.target.value });
                setErrors({
                  ...errors,
                  wantedExperience: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Sprachanforderungen
            <Error>
              {errors.wantedLanguage === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.wantedLanguage}
              onChange={(e) => {
                setCompany({ ...company, wantedLanguage: e.target.value });
                setErrors({
                  ...errors,
                  wantedLanguage: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, wantedLanguage: e.target.value });
                setErrors({
                  ...errors,
                  wantedLanguage: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Weitere spezifische Anforderungen
            <Error>
              {errors.otherRequirements === false
                ? t("apply.invalidField")
                : ""}
            </Error>
            <TextArea
              rows={3}
              type="text"
              value={company.otherRequirements}
              onChange={(e) => {
                setCompany({ ...company, otherRequirements: e.target.value });
                setErrors({
                  ...errors,
                  otherRequirements: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, otherRequirements: e.target.value });
                setErrors({
                  ...errors,
                  otherRequirements: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Gewünschter Startzeitpunkt
            <Error>
              {errors.startTime === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.startTime}
              onChange={(e) => {
                setCompany({ ...company, startTime: e.target.value });
                setErrors({
                  ...errors,
                  startTime: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, startTime: e.target.value });
                setErrors({
                  ...errors,
                  startTime: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Angestrebte Vertragsdauer
            <Error>
              {errors.contractDuration === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.contractDuration}
              onChange={(e) => {
                setCompany({ ...company, contractDuration: e.target.value });
                setErrors({
                  ...errors,
                  contractDuration: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, contractDuration: e.target.value });
                setErrors({
                  ...errors,
                  contractDuration: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Kontaktinformationen (Name)
            <Error>
              {errors.contactName === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.contactName}
              onChange={(e) => {
                setCompany({ ...company, contactName: e.target.value });
                setErrors({
                  ...errors,
                  contactName: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, contactName: e.target.value });
                setErrors({
                  ...errors,
                  contactName: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Kontaktinformationen (E-Mail)
            <Error>
              {errors.contactEmail === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.contactEmail}
              onChange={(e) => {
                setCompany({ ...company, contactEmail: e.target.value });
                setErrors({
                  ...errors,
                  contactEmail: Yup.string()
                    .email()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, contactEmail: e.target.value });
                setErrors({
                  ...errors,
                  contactEmail: Yup.string()
                    .email()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <FormController>
          <label>
            Kontaktinformationen (Telefonnummer)
            <Error>
              {errors.contactPhone === false ? t("apply.invalidField") : ""}
            </Error>
            <Input
              type="text"
              value={company.contactPhone}
              onChange={(e) => {
                setCompany({ ...company, contactPhone: e.target.value });
                setErrors({
                  ...errors,
                  contactPhone: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
              onBlur={(e) => {
                setCompany({ ...company, contactPhone: e.target.value });
                setErrors({
                  ...errors,
                  contactPhone: Yup.string()
                    .required()
                    .isValidSync(e.target.value),
                });
              }}
            />
          </label>
        </FormController>
        <label>
          <CheckBox
            type="checkbox"
            name="terms"
            checked={company.terms}
            onChange={() => {
              setCompany({ ...company, terms: !company.terms });
              setErrors({
                ...errors,
                terms: Yup.boolean()
                  .oneOf([true], t("apply.mustAccept"))
                  .isValidSync(company.terms),
              });
            }}
          />
          {t("apply.iAccept")}
          <a href="/terms" style={{ textDecoration: "underline" }}>
            {t("apply.terms")}
          </a>
          {t("apply.and")}
          <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
            {t("apply.gdpr")}
          </a>
          <Error>{company.terms === false ? t("apply.mustAccept") : ""}</Error>
        </label>

        {successMsg ? (
          <SuccessAlert>
            <Bold>{t("apply.success")}</Bold>
            <p>{successMsg}</p>
          </SuccessAlert>
        ) : null}

        {errorMsg ? (
          <ErrorAlert>
            <Bold>{t("apply.error")}</Bold>
            <p>{errorMsg}</p>
          </ErrorAlert>
        ) : null}

        <SubmitButton type="submit" disabled={submitting}>
          {loading === 0 ? (
            <span className="text">absenden</span>
          ) : loading === 1 ? (
            <span>{t("apply.loading")}</span>
          ) : (
            <span>{t("apply.done")}</span>
          )}
        </SubmitButton>
      </Form>
    </FormContainer>
  );
};
