/* eslint-disable global-require */
import "tailwindcss/dist/base.css";
import "./styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ComponentRenderer from "./ComponentRenderer";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Impressum from "./pages/Impressum";
import Apply from "./pages/Apply";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import ForCompanies from "./pages/ForCompanies";
import CompanyContact from "./pages/CompanyContact";
import ForApplicants from "./pages/forApplicants";
import Services from "./pages/Services";
import OurCustomerService from "./pages/OurCustomerService";
import CompanyFAQS from "./pages/CompanyFAQS";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: require("./lang/en"),
      de: require("./lang/de"),
      es: require("./lang/es"),
    },
    lng: window.localStorage.getItem("lang") || "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/terms">
          <TermsOfService />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/apply">
          <Apply />
        </Route>
        <Route path="/for-companies">
          <Services />
        </Route>
        <Route path="/for-applicants">
          <ForApplicants />
        </Route>

        <Route path="/services">
          <Services />
        </Route>

        <Route path="/our-customer-service">
          <OurCustomerService />
        </Route>

        <Route path="/company-faqs">
          <CompanyFAQS />
        </Route>

        <Route path="/company-contact">
          <CompanyContact />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
