import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import FormCompany from "../components/FormCompany";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col items-center mb-8`;

export default () => {
  const [t] = useTranslation();

  return (
    <AnimationRevealPage>
      <Nav />
      <HeadingInfoContainer>
        <HeadingTitle>{t("contactUs.title")}</HeadingTitle>
      </HeadingInfoContainer>

      <Content>
        <FormCompany />
      </Content>

      <Footer />
    </AnimationRevealPage>
  );
};
