import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import HeaderBase, { NavLinks, NavLink } from "./headers/light";

const Header = tw(HeaderBase)`max-w-none`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export default () => {
  const [t, i18n] = useTranslation();

  const [langLink, setLangLink] = useState(
    window.localStorage.getItem("lang") || "en"
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">{t("nav.home")}</NavLink>
      <NavLink href="/for-companies">{t("nav.forCompanies")}</NavLink>
      <NavLink href="/for-applicants">{t("nav.forApplicants")}</NavLink>
      <NavLink href="/contact">{t("nav.contact")}</NavLink>
    </NavLinks>,
    <select
      key={2}
      value={langLink}
      onChange={(event) => {
        const selectedLang = event.target.value;
        window.localStorage.setItem("lang", selectedLang);
        changeLanguage(selectedLang);
        setLangLink(selectedLang);
      }}
    >
      <option value="en" key="en">
        English 🇬🇧
      </option>
      <option value="de" key="de">
        Deutsch 🇩🇪
      </option>
      <option value="es" key="es">
        Español 🇪🇸
      </option>
    </select>,
  ];
  return (
    <>
      <Header links={navLinks} />
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.accept")}
        buttonStyle={{ background: "#2a2786", color: "#fff" }}
        data-nosnippet
      >
        {t("cookies.content")}
      </CookieConsent>
    </>
  );
};
