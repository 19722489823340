import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "../components/misc/Layouts";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { SectionHeading } from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-lg font-bold mt-8`}
  }
`;

export default () => {
  const [t] = useTranslation();

  return (
    <AnimationRevealPage>
      <Nav />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("privacy.title")}</Heading>
          </HeadingRow>
          <Text>
            <p>Datenschutzerkl&auml;rung</p>
            <p>Stand: 20. Dezember 2023</p>
            <p>
              <br />
            </p>
            <p>Inhalts&uuml;bersicht</p>
            <p>Verantwortlicher</p>
            <p>&Uuml;bersicht der Verarbeitungen</p>
            <p>Ma&szlig;gebliche Rechtsgrundlagen</p>
            <p>Sicherheitsma&szlig;nahmen</p>
            <p>&Uuml;bermittlung von personenbezogenen Daten</p>
            <p>Gesch&auml;ftliche Leistungen</p>
            <p>Registrierung, Anmeldung und Nutzerkonto</p>
            <p>Kontakt- und Anfragenverwaltung</p>
            <p>Verantwortlicher</p>
            <p>Germanyjobsnow.com</p>
            <p>R&uuml;ckertweg.23</p>
            <p>95447 Bayreuth</p>
            <p>
              <br />
            </p>
            <p>E-Mail-Adresse:</p>
            <p>contact@germanyjobsnow.com</p>
            <p>
              <br />
            </p>
            <p>&Uuml;bersicht der Verarbeitungen</p>
            <p>
              Die nachfolgende &Uuml;bersicht fasst die Arten der verarbeiteten
              Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf
              die betroffenen Personen.
            </p>
            <p>
              <br />
            </p>
            <p>Arten der verarbeiteten Daten</p>
            <p>Bestandsdaten.</p>
            <p>Zahlungsdaten.</p>
            <p>Kontaktdaten.</p>
            <p>Inhaltsdaten.</p>
            <p>Vertragsdaten.</p>
            <p>Nutzungsdaten.</p>
            <p>Meta-, Kommunikations- und Verfahrensdaten.</p>
            <p>Kategorien betroffener Personen</p>
            <p>Interessenten.</p>
            <p>Kommunikationspartner.</p>
            <p>Nutzer.</p>
            <p>Gesch&auml;fts- und Vertragspartner.</p>
            <p>Zwecke der Verarbeitung</p>
            <p>
              Erbringung vertraglicher Leistungen und Erf&uuml;llung
              vertraglicher Pflichten.
            </p>
            <p>Kontaktanfragen und Kommunikation.</p>
            <p>Sicherheitsma&szlig;nahmen.</p>
            <p>B&uuml;ro- und Organisationsverfahren.</p>
            <p>Verwaltung und Beantwortung von Anfragen.</p>
            <p>Feedback.</p>
            <p>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </p>
            <p>Ma&szlig;gebliche Rechtsgrundlagen</p>
            <p>
              Ma&szlig;gebliche Rechtsgrundlagen nach der DSGVO: Im Folgenden
              erhalten Sie eine &Uuml;bersicht der Rechtsgrundlagen der DSGVO,
              auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
              nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
              nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
              Sitzland gelten k&ouml;nnen. Sollten ferner im Einzelfall
              speziellere Rechtsgrundlagen ma&szlig;geblich sein, teilen wir
              Ihnen diese in der Datenschutzerkl&auml;rung mit.
            </p>
            <p>
              <br />
            </p>
            <p>
              Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1
              S. 1 lit. b) DSGVO) - Die Verarbeitung ist f&uuml;r die
              Erf&uuml;llung eines Vertrags, dessen Vertragspartei die
              betroffene Person ist, oder zur Durchf&uuml;hrung vorvertraglicher
              Ma&szlig;nahmen erforderlich, die auf Anfrage der betroffenen
              Person erfolgen.
            </p>
            <p>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO) - Die
              Verarbeitung ist zur Erf&uuml;llung einer rechtlichen
              Verpflichtung erforderlich, der der Verantwortliche unterliegt.
            </p>
            <p>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) - Die
              Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              &uuml;berwiegen.
            </p>
            <p>
              Nationale Datenschutzregelungen in Deutschland: Zus&auml;tzlich zu
              den Datenschutzregelungen der DSGVO gelten nationale Regelungen
              zum Datenschutz in Deutschland. Hierzu geh&ouml;rt insbesondere
              das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei
              der Datenverarbeitung (Bundesdatenschutzgesetz &ndash; BDSG). Das
              BDSG enth&auml;lt insbesondere Spezialregelungen zum Recht auf
              Auskunft, zum Recht auf L&ouml;schung, zum Widerspruchsrecht, zur
              Verarbeitung besonderer Kategorien personenbezogener Daten, zur
              Verarbeitung f&uuml;r andere Zwecke und zur &Uuml;bermittlung
              sowie automatisierten Entscheidungsfindung im Einzelfall
              einschlie&szlig;lich Profiling. Ferner k&ouml;nnen
              Landesdatenschutzgesetze der einzelnen Bundesl&auml;nder zur
              Anwendung gelangen.
            </p>
            <p>
              <br />
            </p>
            <p>
              Hinweis auf Geltung DSGVO und Schweizer DSG: Diese
              Datenschutzhinweise dienen sowohl der Informationserteilung nach
              dem schweizerischen Bundesgesetz &uuml;ber den Datenschutz
              (Schweizer DSG) als auch nach der Datenschutzgrundverordnung
              (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass
              aufgrund der breiteren r&auml;umlichen Anwendung und
              Verst&auml;ndlichkeit die Begriffe der DSGVO verwendet werden.
              Insbesondere statt der im Schweizer DSG verwendeten Begriffe
              &bdquo;Bearbeitung&quot; von &bdquo;Personendaten&quot;,
              &quot;&uuml;berwiegendes Interesse&quot; und &quot;besonders
              sch&uuml;tzenswerte Personendaten&quot; werden die in der DSGVO
              verwendeten Begriffe &bdquo;Verarbeitung&quot; von
              &bdquo;personenbezogenen Daten&quot; sowie &quot;berechtigtes
              Interesse&quot; und &quot;besondere Kategorien von Daten&quot;
              verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im
              Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer
              DSG bestimmt.
            </p>
            <p>
              <br />
            </p>
            <p>Sicherheitsma&szlig;nahmen</p>
            <p>
              Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgaben unter
              Ber&uuml;cksichtigung des Stands der Technik, der
              Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde
              und der Zwecke der Verarbeitung sowie der unterschiedlichen
              Eintrittswahrscheinlichkeiten und des Ausma&szlig;es der Bedrohung
              der Rechte und Freiheiten nat&uuml;rlicher Personen geeignete
              technische und organisatorische Ma&szlig;nahmen, um ein dem Risiko
              angemessenes Schutzniveau zu gew&auml;hrleisten.
            </p>
            <p>
              <br />
            </p>
            <p>
              Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der
              Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten
              durch Kontrolle des physischen und elektronischen Zugangs zu den
              Daten als auch des sie betreffenden Zugriffs, der Eingabe, der
              Weitergabe, der Sicherung der Verf&uuml;gbarkeit und ihrer
              Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
              Wahrnehmung von Betroffenenrechten, die L&ouml;schung von Daten
              und Reaktionen auf die Gef&auml;hrdung der Daten
              gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl
              von Hardware, Software sowie Verfahren entsprechend dem Prinzip
              des Datenschutzes, durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen.
            </p>
            <p>
              <br />
            </p>
            <p>
              TLS/SSL-Verschl&uuml;sselung (https): Um die Daten der Benutzer,
              die &uuml;ber unsere Online-Dienste &uuml;bertragen werden, zu
              sch&uuml;tzen, verwenden wir TLS/SSL-Verschl&uuml;sselung. Secure
              Sockets Layer (SSL) ist die Standardtechnologie zur Sicherung von
              Internetverbindungen durch Verschl&uuml;sselung der zwischen einer
              Website oder App und einem Browser (oder zwischen zwei Servern)
              &uuml;bertragenen Daten. Transport Layer Security (TLS) ist eine
              aktualisierte und sicherere Version von SSL. Hyper Text Transfer
              Protocol Secure (HTTPS) wird in der URL angezeigt, wenn eine
              Website durch ein SSL/TLS-Zertifikat gesichert ist.
            </p>
            <p>
              <br />
            </p>
            <p>&Uuml;bermittlung von personenbezogenen Daten</p>
            <p>
              Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
              es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
              selbstst&auml;ndige Organisationseinheiten oder Personen
              &uuml;bermittelt oder sie ihnen gegen&uuml;ber offengelegt werden.
              Zu den Empf&auml;ngern dieser Daten k&ouml;nnen z. B. mit
              IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten
              und Inhalten, die in eine Webseite eingebunden werden,
              geh&ouml;ren. In solchen F&auml;llen beachten wir die gesetzlichen
              Vorgaben und schlie&szlig;en insbesondere entsprechende
              Vertr&auml;ge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
              dienen, mit den Empf&auml;ngern Ihrer Daten ab.
            </p>
            <p>
              <br />
            </p>
            <p>
              Daten&uuml;bermittlung innerhalb der Organisation: Wir k&ouml;nnen
              personenbezogene Daten an andere Stellen innerhalb unserer
              Organisation &uuml;bermitteln oder ihnen den Zugriff auf diese
              Daten gew&auml;hren. Sofern diese Weitergabe zu administrativen
              Zwecken erfolgt, beruht die Weitergabe der Daten auf unseren
              berechtigten unternehmerischen und betriebswirtschaftlichen
              Interessen oder erfolgt, sofern sie Erf&uuml;llung unserer
              vertragsbezogenen Verpflichtungen erforderlich ist oder wenn eine
              Einwilligung der Betroffenen oder eine gesetzliche Erlaubnis
              vorliegt.
            </p>
            <p>
              <br />
            </p>
            <p>Gesch&auml;ftliche Leistungen</p>
            <p>
              Wir verarbeiten Daten unserer Vertrags- und Gesch&auml;ftspartner,
              z. B. Kunden und Interessenten (zusammenfassend bezeichnet als
              &quot;Vertragspartner&quot;) im Rahmen von vertraglichen und
              vergleichbaren Rechtsverh&auml;ltnissen sowie damit verbundenen
              Ma&szlig;nahmen und im Rahmen der Kommunikation mit den
              Vertragspartnern (oder vorvertraglich), z. B., um Anfragen zu
              beantworten.
            </p>
            <p>
              <br />
            </p>
            <p>
              Wir verarbeiten diese Daten, um unsere vertraglichen
              Verpflichtungen zu erf&uuml;llen. Dazu geh&ouml;ren insbesondere
              die Verpflichtungen zur Erbringung der vereinbarten Leistungen,
              etwaige Aktualisierungspflichten und Abhilfe bei
              Gew&auml;hrleistungs- und sonstigen Leistungsst&ouml;rungen.
              Dar&uuml;ber hinaus verarbeiten wir die Daten zur Wahrung unserer
              Rechte und zum Zwecke der mit diesen Pflichten verbundenen
              Verwaltungsaufgaben sowie der Unternehmensorganisation.
              Dar&uuml;ber hinaus verarbeiten wir die Daten auf Grundlage
              unserer berechtigten Interessen an einer
              ordnungsgem&auml;&szlig;en und betriebswirtschaftlichen
              Gesch&auml;ftsf&uuml;hrung sowie an Sicherheitsma&szlig;nahmen zum
              Schutz unserer Vertragspartner und unseres Gesch&auml;ftsbetriebes
              vor Missbrauch, Gef&auml;hrdung ihrer Daten, Geheimnisse,
              Informationen und Rechte (z. B. zur Beteiligung von
              Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie
              Subunternehmern, Banken, Steuer- und Rechtsberatern,
              Zahlungsdienstleistern oder Finanzbeh&ouml;rden). Im Rahmen des
              geltenden Rechts geben wir die Daten von Vertragspartnern nur
              insoweit an Dritte weiter, als dies f&uuml;r die vorgenannten
              Zwecke oder zur Erf&uuml;llung gesetzlicher Pflichten erforderlich
              ist. &Uuml;ber weitere Formen der Verarbeitung, z. B. zu
              Marketingzwecken, werden die Vertragspartner im Rahmen dieser
              Datenschutzerkl&auml;rung informiert.
            </p>
            <p>
              <br />
            </p>
            <p>
              Welche Daten f&uuml;r die vorgenannten Zwecke erforderlich sind,
              teilen wir den Vertragspartnern vor oder im Rahmen der
              Datenerhebung, z. B. in Onlineformularen, durch besondere
              Kennzeichnung (z. B. Farben) bzw. Symbole (z. B. Sternchen
              o.&auml;.), oder pers&ouml;nlich mit.
            </p>
            <p>
              <br />
            </p>
            <p>
              Wir l&ouml;schen die Daten nach Ablauf gesetzlicher
              Gew&auml;hrleistungs- und vergleichbarer Pflichten, d. h.,
              grunds&auml;tzlich nach Ablauf von 4 Jahren, es sei denn, dass die
              Daten in einem Kundenkonto gespeichert werden, z. B., solange sie
              aus gesetzlichen Gr&uuml;nden der Archivierung aufbewahrt werden
              m&uuml;ssen. Die gesetzliche Aufbewahrungsfrist betr&auml;gt bei
              steuerrechtlich relevanten Unterlagen sowie bei
              Handelsb&uuml;chern, Inventaren, Er&ouml;ffnungsbilanzen,
              Jahresabschl&uuml;ssen, die zum Verst&auml;ndnis dieser Unterlagen
              erforderlichen Arbeitsanweisungen und sonstigen
              Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei
              empfangenen Handels- und Gesch&auml;ftsbriefen und Wiedergaben der
              abgesandten Handels- und Gesch&auml;ftsbriefe sechs Jahre. Die
              Frist beginnt mit Ablauf des Kalenderjahres, in dem die letzte
              Eintragung in das Buch gemacht, das Inventar, die
              Er&ouml;ffnungsbilanz, der Jahresabschluss oder der Lagebericht
              aufgestellt, der Handels- oder Gesch&auml;ftsbrief empfangen oder
              abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die
              Aufzeichnung vorgenommen worden ist oder die sonstigen Unterlagen
              entstanden sind.
            </p>
            <p>
              <br />
            </p>
            <p>
              Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
              Plattformen einsetzen, gelten im Verh&auml;ltnis zwischen den
              Nutzern und den Anbietern die Gesch&auml;ftsbedingungen und
              Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.
            </p>
            <p>
              <br />
            </p>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z. B. Namen, Adressen);
              Zahlungsdaten (z. B. Bankverbindungen, Rechnungen,
              Zahlungshistorie); Kontaktdaten (z. B. E-Mail, Telefonnummern);
              Vertragsdaten (z. .B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie).
            </p>
            <p>
              Betroffene Personen: Interessenten. Gesch&auml;fts- und
              Vertragspartner.
            </p>
            <p>
              Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen und
              Erf&uuml;llung vertraglicher Pflichten; Kontaktanfragen und
              Kommunikation; B&uuml;ro- und Organisationsverfahren. Verwaltung
              und Beantwortung von Anfragen.
            </p>
            <p>
              Rechtsgrundlagen: Vertragserf&uuml;llung und vorvertragliche
              Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Rechtliche
              Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte
              Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </p>
            <p>Registrierung, Anmeldung und Nutzerkonto</p>
            <p>
              Nutzer k&ouml;nnen ein Nutzerkonto anlegen. Im Rahmen der
              Registrierung werden den Nutzern die erforderlichen Pflichtangaben
              mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf
              Grundlage vertraglicher Pflichterf&uuml;llung verarbeitet. Zu den
              verarbeiteten Daten geh&ouml;ren insbesondere die
              Login-Informationen (Nutzername, Passwort sowie eine
              E-Mail-Adresse).
            </p>
            <p>
              <br />
            </p>
            <p>
              Im Rahmen der Inanspruchnahme unserer Registrierungs- und
              Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir
              die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung.
              Die Speicherung erfolgt auf Grundlage unserer berechtigten
              Interessen als auch jener der Nutzer an einem Schutz vor
              Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe
              dieser Daten an Dritte erfolgt grunds&auml;tzlich nicht, es sei
              denn, sie ist zur Verfolgung unserer Anspr&uuml;che erforderlich
              oder es besteht eine gesetzliche Verpflichtung hierzu.
            </p>
            <p>
              <br />
            </p>
            <p>
              Die Nutzer k&ouml;nnen &uuml;ber Vorg&auml;nge, die f&uuml;r deren
              Nutzerkonto relevant sind, wie z. B. technische &Auml;nderungen,
              per E-Mail informiert werden.
            </p>
            <p>
              <br />
            </p>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z. B. Namen, Adressen);
              Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z. B.
              Eingaben in Onlineformularen); Meta-, Kommunikations- und
              Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, Einwilligungsstatus).
            </p>
            <p>
              Betroffene Personen: Nutzer (z. .B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
            <p>
              Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen und
              Erf&uuml;llung vertraglicher Pflichten;
              Sicherheitsma&szlig;nahmen; Verwaltung und Beantwortung von
              Anfragen. Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit.
            </p>
            <p>
              Rechtsgrundlagen: Vertragserf&uuml;llung und vorvertragliche
              Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte
              Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </p>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <p>
              <br />
            </p>
            <p>
              Keine Aufbewahrungspflicht f&uuml;r Daten: Es obliegt den Nutzern,
              ihre Daten bei erfolgter K&uuml;ndigung vor dem Vertragsende zu
              sichern. Wir sind berechtigt, s&auml;mtliche w&auml;hrend der
              Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu
              l&ouml;schen; Rechtsgrundlagen: Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </p>
            <p>Kontakt- und Anfragenverwaltung</p>
            <p>
              Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
              E-Mail, Telefon oder via soziale Medien) sowie im Rahmen
              bestehender Nutzer- und Gesch&auml;ftsbeziehungen werden die
              Angaben der anfragenden Personen verarbeitet soweit dies zur
              Beantwortung der Kontaktanfragen und etwaiger angefragter
              Ma&szlig;nahmen erforderlich ist.
            </p>
            <p>
              <br />
            </p>
            <p>
              Verarbeitete Datenarten: Kontaktdaten (z. B. E-Mail,
              Telefonnummern); Inhaltsdaten (z. B. Eingaben in
              Onlineformularen); Nutzungsdaten (z. B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
              Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, Einwilligungsstatus).
            </p>
            <p>Betroffene Personen: Kommunikationspartner.</p>
            <p>
              Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation;
              Verwaltung und Beantwortung von Anfragen; Feedback (z. B. Sammeln
              von Feedback via Online-Formular). Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit.
            </p>
            <p>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f) DSGVO). Vertragserf&uuml;llung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </p>
            <p>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </p>
            <p>
              <br />
            </p>
            <p>
              Kontaktformular: Wenn Nutzer &uuml;ber unser Kontaktformular,
              E-Mail oder andere Kommunikationswege mit uns in Kontakt treten,
              verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten
              zur Bearbeitung des mitgeteilten Anliegens; Rechtsgrundlagen:
              Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1
              S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
              lit. f) DSGVO).
            </p>
            <p>
              <br />
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
