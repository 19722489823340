import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col mb-8 items-center`;
const Container = tw.div`p-8 lg:w-3/4 w-full `;
const Section = tw.div`mb-4`;
const Paragraph = tw.p`text-gray-700 p-2`;

const buttonRoundedCss = tw`rounded-full`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block lg:w-1/3 w-full tracking-wide text-center py-5`;

export default () => (
  <AnimationRevealPage>
    <Nav />
    <Helmet>
      <title>Unsere Kundenservice - GermanyJobsNow</title>

      <meta name="description" content="" />
      <meta
        name="keywords"
        content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
      />
    </Helmet>

    <HeadingInfoContainer>
      <HeadingTitle>Unsere Kundenservice</HeadingTitle>
    </HeadingInfoContainer>
    <Content>
      <Container>
        <Section>
          <Paragraph>
            herzlich willkommen bei unserem lokal verankerten Kundenservice in
            Bayreuth! Wir verstehen die Bedeutung von persönlicher Betreuung und
            Engagement, und unser Team vor Ort steht Ihnen zur Verfügung, um
            Ihre Anforderungen im Bereich Fachkräftevermittlung und Ausbildung
            aus dem Ausland bestmöglich zu erfüllen.
          </Paragraph>
          <Paragraph>Unsere Präsenz in Bayreuth bietet:</Paragraph>
          <Paragraph>Persönliche Beratung:</Paragraph>
          <Paragraph>
            Unsere erfahrenen Berater in Bayreuth stehen Ihnen persönlich zur
            Verfügung, um Ihre spezifischen Anforderungen zu besprechen. Wir
            nehmen uns die Zeit, Ihre Bedürfnisse zu verstehen und
            maßgeschneiderte Lösungen anzubieten.
          </Paragraph>
          <Paragraph>Individuelle Anpassung:</Paragraph>
          <Paragraph>
            Wir passen unsere Dienstleistungen an die einzigartigen Bedürfnisse
            Ihres Unternehmens an. Egal, ob es um die Suche nach Fachkräften
            oder die Gestaltung von Ausbildungsprogrammen geht, wir setzen auf
            maßgeschneiderte Lösungen.
          </Paragraph>
          <Paragraph>Lokale Expertise:</Paragraph>
          <Paragraph>
            Unser Team in Bayreuth verfügt über umfassende lokale Expertise und
            Kenntnisse des Arbeitsmarktes. Wir verstehen die spezifischen
            Anforderungen der Region und können Ihnen daher maßgeschneiderte
            Lösungen anbieten.
          </Paragraph>
          <Paragraph>
            Persönliche Betreuung während des gesamten Prozesses:
          </Paragraph>
          <Paragraph>
            Von der ersten Beratung bis zur erfolgreichen Integration Ihrer
            neuen Mitarbeiter oder Auszubildenden stehen wir Ihnen persönlich
            zur Seite. Wir legen großen Wert darauf, dass Sie sich gut betreut
            fühlen.
          </Paragraph>
          <Paragraph>Flexibilität und Erreichbarkeit:</Paragraph>
          <Paragraph>
            Unsere Flexibilität und Erreichbarkeit sind unser Versprechen an
            Sie. Wir sind für Sie da, wenn Sie uns brauchen, und passen uns
            Ihren zeitlichen Anforderungen an.
          </Paragraph>
          <Paragraph>
            Kontaktieren Sie uns telefonisch oder per E-Mail. Alle
            Kontaktinformationen finden Sie auf unserer Webseite
          </Paragraph>
          <Paragraph>
            Ihr Erfolg ist unser gemeinsames Ziel – Lassen Sie uns persönlich
            zusammenarbeiten für eine erfolgreiche Zukunft!
          </Paragraph>
          <PrimaryButton as="a" href="company-contact" css={buttonRoundedCss}>
            Kontaktieren Sie uns
          </PrimaryButton>
        </Section>
      </Container>
    </Content>

    <Footer />
  </AnimationRevealPage>
);
