import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-8.svg";
import { Container, ContentWithPaddingXl } from "./misc/Layouts.js";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question:
        "Welche Dienstleistungen bietet Ihr Unternehmen im Bereich Fachkräftevermittlung und Ausbildung aus dem Ausland an?",
      answer:
        "Germanyjbsnow.com bietet umfassende Dienstleistungen für die Rekrutierung von Fachkräften und die Ausbildung aus dem Ausland an. Dazu gehören die Identifizierung passender Kandidaten, die Unterstützung bei Visa- und Einwanderungsprozessen sowie maßgeschneiderte Ausbildungsprogramme.",
    },
    {
      question: "In welchen Branchen und Berufsfeldern haben Sie Erfahrung?",
      answer:
        "Unsere Expertise erstreckt sich über verschiedene Branchen, darunter Technologie, Ingenieurwesen, Gesundheitswesen, Finanzen und mehr. Wir passen unsere Dienstleistungen an die spezifischen Anforderungen unserer Kunden an.",
    },
    {
      question: "Wie läuft der Rekrutierungsprozess ab?",
      answer:
        "Der Rekrutierungsprozess beginnt mit einer detaillierten Bedarfsanalyse. Nach Identifikation potenzieller Kandidaten folgen Vorstellungsgespräche, Auswahlverfahren und Unterstützung bei der Visum- und Einwanderungsprozedur. Wir begleiten Sie während des gesamten Prozesses.",
    },
    {
      question: "Bieten Sie maßgeschneiderte Ausbildungsprogramme an?",
      answer:
        "Ja, wir entwickeln maßgeschneiderte Ausbildungsprogramme, die den spezifischen Anforderungen Ihres Unternehmens entsprechen. Diese Programme können sowohl fachspezifische Schulungen als auch kulturelle Integrationselemente umfassen.",
    },
    {
      question:
        "Wie unterstützen Sie Unternehmen bei der Integration ausländischer Fachkräfte?",
      answer:
        "Wir bieten nicht nur Rekrutierungsdienstleistungen, sondern auch Unterstützung bei der kulturellen Integration der ausländischen Fachkräfte. Dies beinhalten Orientierungssitzungen, kulturelle Sensibilisierung und die Förderung einer integrativen Unternehmenskultur.",
    },
    {
      question:
        "Welche Länder deckt Ihr Service für die Vermittlung von Fachkräften und Ausbildung ab?",
      answer:
        "Unsere Dienstleistungen erstrecken sich über verschiedene Länder, um sicherzustellen, dass wir die besten Talente gemäß den Anforderungen unserer Kunden identifizieren können. Kontaktieren Sie uns, um Informationen zu den Verfügbarkeiten in Ihren Zielländern zu erhalten.",
    },
    {
      question:
        "Wie gewährleisten Sie die Qualität der ausländischen Fachkräfte und Ausbildungsprogramme?",
      answer:
        "Wir setzen strenge Auswahlkriterien und Qualitätskontrollen ein, um sicherzustellen, dass die ausländischen Fachkräfte den Anforderungen entsprechen. Unsere Ausbildungsprogramme werden entsprechend den branchenspezifischen Standards und den Bedürfnissen unserer Kunden gestaltet.",
    },
    {
      question: "Wie kann mein Unternehmen mit Ihrem Team in Kontakt treten?",
      answer:
        "Sie können uns per E-Mail oder Telefon kontaktieren, um weitere Informationen zu unseren Dienstleistungen zu erhalten. Besuchen Sie auch gerne unsere Büros persönlich, um Ihre Anforderungen mit unseren Experten zu besprechen. Alle Kontaktdaten finden Sie auf unserer Webseite.",
    },
  ];

  return (
    <Container id="faqs">
      <ContentWithPaddingXl>
        <Column>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
