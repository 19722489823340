import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col mb-8 items-center`;
const Container = tw.div`p-8 lg:w-3/4 w-full `;
const Section = tw.div`mb-4`;
const Paragraph = tw.p`text-gray-700 p-2`;

const buttonRoundedCss = tw`rounded-full`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block lg:w-1/3 w-full tracking-wide text-center py-5`;

export default () => {
  const [t] = useTranslation();

  return (
    <AnimationRevealPage>
      <Nav />
      <Helmet>
        <title>{t("forApplicants.title")} - GermanyJobsNow</title>
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
        />
      </Helmet>

      <HeadingInfoContainer>
        <HeadingTitle>{t("forApplicants.title")}</HeadingTitle>
      </HeadingInfoContainer>
      <Content>
        <Container>
          <Section>
            <Paragraph>{t("forApplicants.p1")}</Paragraph>
            <Paragraph>{t("forApplicants.p2")}</Paragraph>
            <Paragraph>{t("forApplicants.p3")}</Paragraph>
            <Paragraph>{t("forApplicants.p4")}</Paragraph>
            <Paragraph>{t("forApplicants.p5")}</Paragraph>
            <Paragraph>{t("forApplicants.p6")}</Paragraph>
            <Paragraph>{t("forApplicants.p7")}</Paragraph>
            <Paragraph>{t("forApplicants.p8")}</Paragraph>
            <Paragraph>{t("forApplicants.p9")}</Paragraph>
            <Paragraph>{t("forApplicants.p11")}</Paragraph>
            <Paragraph>{t("forApplicants.p12")}</Paragraph>
            <PrimaryButton as="a" href="apply" css={buttonRoundedCss}>
              {t("forApplicants.contact")}
            </PrimaryButton>
            <Paragraph>{t("forApplicants.p13")}</Paragraph>
            <Paragraph>{t("forApplicants.p14")}</Paragraph>
          </Section>
        </Container>
      </Content>

      <Footer />
    </AnimationRevealPage>
  );
};
