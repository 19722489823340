import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'react-i18next';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { SectionHeading as HeadingTitle } from '../components/misc/Headings';
import { SectionDescription } from '../components/misc/Typography.js';

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col items-center mb-8`;
const Description = tw(
  SectionDescription
)`mt-4 mb-4 text-center text-lg text-gray-700 max-w-lg`;

const Link = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export default () => {
  const [t] = useTranslation();

  return (
    <AnimationRevealPage>
      <Nav />
      <HeadingInfoContainer>
        <HeadingTitle>{t('contactUs.title')}</HeadingTitle>
      </HeadingInfoContainer>

      <Content>
        <Description>{t('contactUs.content')}</Description>
        <Link href="mailto:contact@germanyjobsnow.com">
          contact@germanyjobsnow.com
        </Link>
      </Content>

      <Footer />
    </AnimationRevealPage>
  );
};
