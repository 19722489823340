import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";

import { ReactComponent as InstagramIcon } from "../images/instagram-icon.svg";
import { ReactComponent as FacebookIcon } from "../images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "../images/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between md:w-full -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/2 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  const [t] = useTranslation();

  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>{t("nav.forApplicants")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="apply">{t("apply.apply")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="for-applicants">{t("nav.forApplicants")}</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>{t("nav.forCompanies")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="services">{t("nav.services")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="our-customer-service">
                  {t("nav.ourCustomerService")}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="company-faqs">{t("nav.company-faqs")}</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>{t("footer.legal.title")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/privacy-policy">{t("footer.legal.privacy")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/terms">{t("footer.legal.terms")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/impressum">Impressum</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} GermanyJobsNow.com
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.instagram.com/germanyjobsnow/">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/GermanyJobsNowCom">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/germanyjobsnow/">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
