/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {
  SectionHeading as HeadingTitle,
  Subheading,
} from "../components/misc/Headings";

import Api from "../Api";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Error = tw.span`text-red-500 text-sm ml-2`;
const FormController = tw.div`grid lg:grid-cols-2 grid-cols-1 gap-4`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-screen-md`;
const Input = tw.input`w-full px-3 py-3 mb-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const Select = tw.select`w-full px-3 py-3 mb-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const CheckBox = tw.input`px-3 py-3 mb-2 font-medium text-sm mt-5 mx-3 ml-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 mb-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const SuccessAlert = tw.div`bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-3 rounded-lg`;
const ErrorAlert = tw.div`bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-3 rounded-lg`;
const Bold = tw.p`font-bold`;

export default () => {
  const [t] = useTranslation();

  const lang = window.localStorage.getItem("lang") || "en";

  const [captcha, setCaptcha] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(0);
  const [countries, setCountries] = useState([]);
  const [offersByReason, setOffersByReason] = useState([]);

  const [errors, setErrors] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    confirmEmail: undefined,
    gender: undefined,
    birthDate: undefined,
    CountryId: undefined,
    city: undefined,
    reasonToGo: undefined,
    OfferId: undefined,
    otherOffer: undefined,
    englishLevel: undefined,
    germanLevel: undefined,
    terms: undefined,
    cvFile: undefined,
    langFile: undefined,
    diplomaFile: undefined,
  });
  // console.log('errors');
  // console.log(errors);

  const [client, setClient] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    CountryId: "",
    city: "",

    email: "",
    confirmEmail: "",

    reasonToGo: "",
    OfferId: "",
    otherOffer: "",
    englishLevel: "",
    germanLevel: "",
    terms: false,
    cvFile: undefined,
    langFile: undefined,
    diplomaFile: undefined,
  });

  // console.log('client');
  // console.log(client);

  const schema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    gender: Yup.string().required(),
    birthDate: Yup.date().required(),
    CountryId: Yup.string().required(),
    city: Yup.string().required(),
    reasonToGo: Yup.number().required(),
    OfferId: Yup.string(),
    otherOffer: Yup.string().when("OfferId", {
      is: true,
      then: Yup.string().required(),
    }),
    englishLevel: Yup.string().required(),
    germanLevel: Yup.string().required(),
    terms: Yup.boolean().oneOf([true], t("apply.mustAccept")),
    cvFile: Yup.mixed()
      .test(
        "fileType",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      )
      .required(),
    langFile: Yup.mixed()
      .test(
        "fileType",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      )
      .required(),
    diplomaFile: Yup.mixed()
      .test(
        "fileType",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      )
      .required(),
  });

  const getCountries = async () => {
    const res = await Api.getCountries();
    // console.log('res countries');
    // console.log(res);
    if (res.success) {
      setCountries(res.data);
    }
  };

  const getOffersByReason = async (reason) => {
    if (reason !== "") {
      const res = await Api.getOffersByReason(reason);
      // console.log('res getOffersByReason');
      // console.log(res);
      if (res.success) {
        setOffersByReason(res.data);
      }
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setLoading(1);
    setSuccessMsg("");
    setErrorMsg("");
    setCountries("");

    // console.log("client");
    // console.log(client);

    schema.isValid(client).then(async (valid) => {
      if (valid) {
        try {
          setLoading(2);
          Api.AddClient(client).then(
            (success) => {
              if (success.success) {
                try {
                  const formData = new FormData();
                  formData.append("cvFile", client.cvFile);
                  axios.post(
                    `${process.env.REACT_APP_API_URL}/clients/upload/file/${success.data.id}/cv`,
                    formData
                  );
                } catch (error) {
                  console.error("cvFile upload failed:", error);
                }
                // uplaod langFile
                try {
                  const formData = new FormData();
                  formData.append("langFile", client.langFile);

                  axios.post(
                    `${process.env.REACT_APP_API_URL}/clients/upload/langfile/${success.data.id}/lang`,
                    formData
                  );
                } catch (error) {
                  console.error("langFile upload failed:", error);
                }
                // uplaod diplomaFile
                try {
                  const formData = new FormData();
                  formData.append("diplomaFile", client.diplomaFile);

                  axios.post(
                    `${process.env.REACT_APP_API_URL}/clients/upload/diplomafile/${success.data.id}/diploma`,
                    formData
                  );
                } catch (error) {
                  console.error("diplomaFile upload failed:", error);
                }

                const msg = `${t("apply.thankYou")} ${
                  success.data.firstName
                } ${t("apply.applicationSent")}`;
                // console.log(msg);
                setSuccessMsg(msg);
                setLoading(3);
              }
            },
            (error) => {
              // console.log(error);

              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              setErrorMsg(resMessage);
              setLoading(0);
              setSubmitting(false);
              // console.log(resMessage);
            }
          );
        } catch (error) {
          // console.log('Error ', error);
          setErrorMsg("Unknown error, try again or contact support");
        }
      } else {
        // console.log('Invalid form');
        setErrorMsg(t("apply.invalidForm"));
        setLoading(0);
        setSubmitting(false);
      }
    });
  };

  return (
    <AnimationRevealPage>
      <Nav />
      <Helmet>
        <title>Apply to Get a Job In Germany - GermanyJobsNow</title>
        <meta
          name="description"
          content="Apply to Get a Job or studies In Germany Where we help you with all the steps that you need"
        />
        <meta
          name="keywords"
          content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
        />
      </Helmet>
      <HeadingInfoContainer>
        <HeadingTitle>{t("apply.apply")}</HeadingTitle>
        <Subheading>{t("apply.applySub")}</Subheading>
      </HeadingInfoContainer>
      <FormContainer>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <FormController>
            <label>
              {t("apply.firstName")}
              <Error>
                {errors.firstName === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="text"
                value={client.firstName}
                onChange={(e) => {
                  setClient({ ...client, firstName: e.target.value });
                  setErrors({
                    ...errors,
                    firstName: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, firstName: e.target.value });
                  setErrors({
                    ...errors,
                    firstName: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              />
            </label>
            <label>
              {t("apply.lastName")}
              <Error>
                {errors.lastName === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="text"
                value={client.lastName}
                onChange={(e) => {
                  setClient({ ...client, lastName: e.target.value });
                  setErrors({
                    ...errors,
                    lastName: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, lastName: e.target.value });
                  setErrors({
                    ...errors,
                    lastName: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              />
            </label>
          </FormController>

          <FormController>
            <label>
              {t("apply.email")}
              <Error>
                {errors.email === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="text"
                value={client.email}
                onChange={(e) => {
                  setClient({ ...client, email: e.target.value });
                  setErrors({
                    ...errors,
                    email: Yup.string()
                      .required()
                      .email()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, email: e.target.value });
                  setErrors({
                    ...errors,
                    email: Yup.string()
                      .required()
                      .email()
                      .isValidSync(e.target.value),
                  });
                }}
              />
            </label>
            <label>
              {t("apply.confirmEmail")}
              <Error>
                {errors.confirmEmail === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="text"
                value={client.confirmEmail}
                onChange={(e) => {
                  setClient({ ...client, confirmEmail: e.target.value });
                  setErrors({
                    ...errors,
                    confirmEmail: Yup.string()
                      .required()
                      .email()
                      .test("match", "Emails do not match", function (email) {
                        return email === client.email;
                      })
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, confirmEmail: e.target.value });
                  setErrors({
                    ...errors,
                    confirmEmail: Yup.string()
                      .required()
                      .email()
                      .test("match", "Emails do not match", function (email) {
                        return email === client.email;
                      })
                      .isValidSync(e.target.value),
                  });
                }}
              />
            </label>
          </FormController>

          <FormController>
            <label>
              {t("apply.gender")}
              <Error>
                {errors.gender === false ? t("apply.invalidField") : ""}
              </Error>
              <Select
                value={client.gender}
                onChange={(e) => {
                  setClient({ ...client, gender: e.target.value });
                  setErrors({
                    ...errors,
                    gender: Yup.string().required().isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, gender: e.target.value });
                  setErrors({
                    ...errors,
                    gender: Yup.string().required().isValidSync(e.target.value),
                  });
                }}
              >
                <option value="">{t("apply.choose")}</option>
                <option value="M">{t("apply.male")}</option>
                <option value="F">{t("apply.female")}</option>
              </Select>
            </label>
            <label>
              {t("apply.birthDate")}
              <Error>
                {errors.birthDate === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="date"
                value={client.birthDate}
                onChange={(e) => {
                  setClient({ ...client, birthDate: e.target.value });
                  setErrors({
                    ...errors,
                    birthDate: Yup.date()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, birthDate: e.target.value });
                  setErrors({
                    ...errors,
                    birthDate: Yup.date()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              />
            </label>
          </FormController>

          <FormController>
            <label>
              {t("apply.country")}
              <Error>
                {errors.CountryId === false ? t("apply.invalidField") : ""}
              </Error>
              <Select
                value={client.CountryId}
                onChange={(e) => {
                  setClient({ ...client, CountryId: e.target.value });
                  setErrors({
                    ...errors,
                    CountryId: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, CountryId: e.target.value });
                  setErrors({
                    ...errors,
                    CountryId: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              >
                <option value="">{t("apply.choose")}</option>
                {countries &&
                  countries.map((country) => (
                    <option value={country.id} key={country.id}>
                      {lang === "en" ? country.nameEN : country.nameDE}
                    </option>
                  ))}
              </Select>
            </label>
            <label>
              {t("apply.city")}
              <Error>
                {errors.city === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="text"
                value={client.city}
                onChange={(e) => {
                  setClient({ ...client, city: e.target.value });
                  setErrors({
                    ...errors,
                    city: Yup.string().required().isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, city: e.target.value });
                  setErrors({
                    ...errors,
                    city: Yup.string().required().isValidSync(e.target.value),
                  });
                }}
              />
            </label>
          </FormController>

          <FormController>
            <label>
              {t("apply.reasonToGo")}
              <Error>
                {errors.reasonToGo === false ? t("apply.invalidField") : ""}
              </Error>
              <Select
                value={client.reasonToGo}
                onChange={(e) => {
                  setClient({ ...client, reasonToGo: e.target.value });
                  setErrors({
                    ...errors,
                    reasonToGo: Yup.number()
                      .required()
                      .isValidSync(e.target.value),
                  });
                  getOffersByReason(e.target.value);
                }}
                onBlur={(e) => {
                  setClient({ ...client, reasonToGo: e.target.value });
                  setErrors({
                    ...errors,
                    reasonToGo: Yup.number()
                      .required()
                      .isValidSync(e.target.value),
                  });
                  getOffersByReason(e.target.value);
                }}
              >
                <option value="">{t("apply.choose")}</option>
                <option value="1">{t("apply.work")}</option>
                <option value="2">{t("apply.apprenticeship")}</option>
              </Select>
            </label>
          </FormController>
          {client.reasonToGo !== "" && offersByReason.length > 0 ? (
            <FormController>
              <label>
                {client.reasonToGo === "1"
                  ? t("apply.workOffer")
                  : t("apply.apprenticeshipOffer")}
                <Error>
                  {errors.OfferId === false ? t("apply.invalidField") : ""}
                </Error>
                <Select
                  value={client.OfferId}
                  onChange={(e) => {
                    setClient({ ...client, OfferId: e.target.value });
                    setErrors({
                      ...errors,
                      OfferId: Yup.string().isValidSync(e.target.value),
                    });
                  }}
                  onBlur={(e) => {
                    setClient({ ...client, OfferId: e.target.value });
                    setErrors({
                      ...errors,
                      OfferId: Yup.string().isValidSync(e.target.value),
                    });
                  }}
                >
                  {offersByReason.length > 0 &&
                    offersByReason.map((offer) => (
                      <option value={offer.id} key={offer.id}>
                        {lang === "en" ? offer.titleEN : offer.titleDE}
                      </option>
                    ))}
                  <option value="">{t("apply.other")}</option>
                </Select>
              </label>
              {client.OfferId === "" ? (
                <label>
                  {t("apply.other")}
                  <Error>
                    {errors.otherOffer === false ? t("apply.invalidField") : ""}
                  </Error>
                  <Input
                    type="text"
                    value={client.otherOffer}
                    onChange={(e) => {
                      setClient({ ...client, otherOffer: e.target.value });
                      setErrors({
                        ...errors,
                        otherOffer: Yup.string()
                          .when("OfferId", {
                            is: true,
                            then: Yup.string().required(),
                          })
                          .isValidSync(e.target.value),
                      });
                    }}
                    onBlur={(e) => {
                      setClient({ ...client, otherOffer: e.target.value });
                      setErrors({
                        ...errors,
                        otherOffer: Yup.string()
                          .when("OfferId", {
                            is: true,
                            then: Yup.string().required(),
                          })
                          .isValidSync(e.target.value),
                      });
                    }}
                  />
                </label>
              ) : null}
            </FormController>
          ) : null}

          <FormController>
            <label>
              {t("apply.englishLevel")}
              <Error>
                {errors.englishLevel === false ? t("apply.invalidField") : ""}
              </Error>
              <Select
                value={client.englishLevel}
                onChange={(e) => {
                  setClient({ ...client, englishLevel: e.target.value });
                  setErrors({
                    ...errors,
                    englishLevel: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, englishLevel: e.target.value });
                  setErrors({
                    ...errors,
                    englishLevel: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              >
                <option value="">{t("apply.choose")}</option>
                <option value="none">{t("apply.noLevel")}</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
              </Select>
            </label>

            <label>
              {t("apply.germanLevel")}
              <Error>
                {errors.germanLevel === false ? t("apply.invalidField") : ""}
              </Error>
              <Select
                value={client.germanLevel}
                onChange={(e) => {
                  setClient({ ...client, germanLevel: e.target.value });
                  setErrors({
                    ...errors,
                    germanLevel: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, germanLevel: e.target.value });
                  setErrors({
                    ...errors,
                    germanLevel: Yup.string()
                      .required()
                      .isValidSync(e.target.value),
                  });
                }}
              >
                <option value="">{t("apply.choose")}</option>
                <option value="none">{t("apply.noLevel")}</option>
                <option value="A1">A1</option>
                <option value="A2">A2</option>
                <option value="B1">B1</option>
                <option value="B2">B2</option>
                <option value="C1">C1</option>
                <option value="C2">C2</option>
              </Select>
            </label>
          </FormController>
          <FormController>
            <label>
              {t("apply.cv")}
              <Error>
                {errors.cvFile === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="file"
                name="cvFile"
                onChange={(e) => {
                  setClient({ ...client, cvFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    cvFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, cvFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    cvFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
              />
            </label>
          </FormController>
          <FormController>
            <label>
              {t("apply.diploma")}
              <Error>
                {errors.diplomaFile === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="file"
                name="diplomaFile"
                onChange={(e) => {
                  setClient({ ...client, diplomaFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    diplomaFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, diplomaFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    diplomaFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
              />
            </label>
          </FormController>
          <FormController>
            <label>
              {t("apply.lang")}
              <Error>
                {errors.langFile === false ? t("apply.invalidField") : ""}
              </Error>
              <Input
                type="file"
                name="langFile"
                onChange={(e) => {
                  setClient({ ...client, langFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    langFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
                onBlur={(e) => {
                  setClient({ ...client, langFile: e.target.files[0] });
                  setErrors({
                    ...errors,
                    langFile: Yup.mixed()
                      .test(
                        "fileType",
                        "Only PDF files are allowed",
                        (value) => value && value.type === "application/pdf"
                      )
                      .required("")
                      .isValidSync(e.target.files[0]),
                  });
                }}
              />
            </label>
          </FormController>
          <p>{t("apply.fileFormatText")}</p>
          <label>
            <CheckBox
              type="checkbox"
              name="terms"
              checked={client.terms}
              onChange={() => {
                setClient({ ...client, terms: !client.terms });
                setErrors({
                  ...errors,
                  terms: Yup.boolean()
                    .oneOf([true], t("apply.mustAccept"))
                    .isValidSync(client.terms),
                });
              }}
            />
            {t("apply.iAccept")}
            <a href="/terms" style={{ textDecoration: "underline" }}>
              {t("apply.terms")}
            </a>
            {t("apply.and")}
            <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
              {t("apply.gdpr")}
            </a>
            <Error>{client.terms === false ? t("apply.mustAccept") : ""}</Error>
          </label>
          <ReCAPTCHA
            sitekey="6LcA1jspAAAAAP4yQEsHN7HKw8Obf_OPZ1nljorI"
            onChange={(val) => setCaptcha(val)}
          />
          {successMsg ? (
            <SuccessAlert>
              <Bold>{t("apply.success")}</Bold>
              <p>{successMsg}</p>
            </SuccessAlert>
          ) : null}

          {errorMsg ? (
            <ErrorAlert>
              <Bold>{t("apply.error")}</Bold>
              <p>{errorMsg}</p>
            </ErrorAlert>
          ) : null}

          <SubmitButton type="submit" disabled={submitting || !captcha}>
            {loading === 0 ? (
              <span className="text">{t("apply.register")}</span>
            ) : loading === 1 ? (
              <span>{t("apply.loading")}</span>
            ) : (
              <span>{t("apply.done")}</span>
            )}
          </SubmitButton>
        </Form>
      </FormContainer>

      <Footer />
    </AnimationRevealPage>
  );
};
