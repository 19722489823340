import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/dot-pattern.svg";
import { NavLink } from "./headers/light.js";
import { SectionHeading } from "./misc/Headings.js";
import { SectionDescription } from "./misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "./misc/Layouts.js";

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug sm:text-base md:text-lg xl:text-2xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block  w-full tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-full rounded-t sm:rounded relative z-10`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export default ({
  imageSrc = "https://images.unsplash.com/photo-1573496130141-209d200cebd8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
  imageDecoratorBlob = true,
  primaryButtonUrl = "apply",
  buttonRounded = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const [t] = useTranslation();
  const features = [
    t("hero.features.f1"),
    t("hero.features.f2"),
    t("hero.features.f3"),
  ];

  return (
    <Container>
      <ContentWithVerticalPadding>
        <Row>
          <TextColumn>
            <Heading>{t("hero.heading")}</Heading>
            <Description>{t("hero.description")}</Description>
            <PrimaryButton
              as="a"
              href={primaryButtonUrl}
              css={buttonRoundedCss}
            >
              {t("button.getStarted")}
            </PrimaryButton>
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIcon />
                  <FeatureText>{feature}</FeatureText>
                </Feature>
              ))}
            </FeatureList>
          </TextColumn>
          <ImageColumn>
            <ImageContainer>
              <Image src={imageSrc} />
              {imageDecoratorBlob && <ImageDecoratorBlob />}
            </ImageContainer>
            <Offsetbackground />
          </ImageColumn>
        </Row>
      </ContentWithVerticalPadding>
    </Container>
  );
};
