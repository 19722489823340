/* eslint-disable react/display-name */
import React from 'react';
import { css } from "styled-components/macro"; //eslint-disable-line

import { Helmet } from 'react-helmet';
import Features from '../components/Features';
import FeatureStats from '../components/FeatureStats';

import FAQ from '../components/Faqs';
import GetStarted from '../components/GetStartedLight';
import Footer from '../components/Footer';
import Offers from '../components/Offers';
import Nav from '../components/Nav';
import Hero from '../components/Hero';
import AnimationRevealPage from '../helpers/AnimationRevealPage.js';

export default () => (
  <AnimationRevealPage>
    <Nav />
    <Helmet>
      <title>Job in Germany for foreigners - GermanyJobsNow</title>
      <meta
        name="description"
        content="We help foreigners find work or apprenticeship for students easily in Germany"
      />
      <meta
        name="keywords"
        content="job in Germany for foreigners, how to get job in Germany without degree, apprenticeship in Germany, studies in Germany"
      />
    </Helmet>
    <Hero />
    <FeatureStats />
    <Features />
    <Offers />
    <FAQ />
    <GetStarted />
    <Footer />
  </AnimationRevealPage>
);
