import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from "react-i18next";
import { Container, ContentWithPaddingXl } from "../components/misc/Layouts";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { SectionHeading } from "../components/misc/Headings";
import AnimationRevealPage from "../helpers/AnimationRevealPage";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
`;

export default () => {
  const [t] = useTranslation();

  return (
    <AnimationRevealPage>
      <Nav />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{t("terms.title")}</Heading>
          </HeadingRow>
          <Text>
            <p>{t("terms.lastUpdate")}</p>
            <p>{t("terms.pleaseRead")}</p>
            <p>{t("terms.conditions")}</p>
            <h2>{t("terms.contractingPartiesTitle")}</h2>
            <p>{t("terms.contractingParties")}</p>
            <h2>{t("terms.freeNeedsAssessmentTitle")}</h2>
            <p>{t("terms.freeNeedsAssessment")}</p>
            <h2>{t("terms.costEstimateMediationTitle")}</h2>
            <p>{t("terms.costEstimateMediation")}</p>
            <h2>{t("terms.paymentTermsTitle")}</h2>
            <p>{t("terms.paymentTerms")}</p>
            <h2>{t("terms.disclosureApplicantInformationTitle")}</h2>
            <p>{t("terms.disclosureApplicantInformation")}</p>
            <h2>{t("terms.confidentialityTitle")}</h2>
            <p>{t("terms.confidentiality")}</p>
            <h2>{t("terms.rightsTitle")}</h2>
            <p>{t("terms.rights")}</p>{" "}
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
