import React from "react";
import tw from "twin.macro";
import Helmet from "react-helmet";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

import { PrimaryButton as PrimaryButtonBase } from "../components/misc/Buttons";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Content = tw.div`flex flex-col mb-8 items-center`;
const Container = tw.div`p-8 lg:w-3/4 w-full `;
const Section = tw.div`mb-4`;
const Heading2 = tw.h2`text-xl font-semibold mb-2`;
const Paragraph = tw.p`text-gray-700 p-2`;

const buttonRoundedCss = tw`rounded-full`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block lg:w-1/4 w-full tracking-wide text-center py-5`;
export default () => (
  <AnimationRevealPage>
    <Nav />
    <Helmet>
      <title>Für Unternehmen - GermanyJobsNow</title>
      <meta name="description" content="" />
      <meta
        name="keywords"
        content="work in germany for foreigners, apprenticeship in Germany, how to study in germany, what should i study in germany"
      />
    </Helmet>

    <HeadingInfoContainer>
      <HeadingTitle>Für Unternehmen </HeadingTitle>
    </HeadingInfoContainer>
    <Content>
      <Container>
        <Section>
          <Heading2>Deutschland braucht Arbeitskräfte mehr denn je</Heading2>
          <Paragraph>
            Deutschland sucht derzeit über 1,2 Millionen qualifizierte
            Arbeitskräfte. Tausende von Auszubildenden werden benötigt.{" "}
          </Paragraph>
        </Section>

        <Section>
          <Paragraph>
            Wenn Sie auf der Suche nach Arbeitnehmern für ihr Unternehmen sind
            oder Ausbildungsplätze aufgrund mangelnder Bewerber nicht besetzen
            können dann sind Sie hier genau richtig.{" "}
          </Paragraph>
          <Heading2>
            Fachkräftemangel und Integration: Die Lösung für erfolgreiches
            Wachstum!
          </Heading2>
          <Paragraph>
            Angesichts des anhaltenden Fachkräftemangels in Deutschland bieten
            wir maßgeschneiderte Strategien, um Ihren Fachkräftebedarf mit
            hochqualifizierten internationalen Talenten zu decken. Als
            Personalvermittlung für ausländische Fachkräfte finden wir Lösungen
            für den Fachkräftemangel. Unser Ziel ist es, die Integration
            ausländischer Mitarbeiter in Ihr Unternehmen zu erleichtern. Wir
            unterstützen Sie bei der Beschaffung von Visa und
            Arbeitserlaubnissen für ausländische Fachkräfte und bieten
            Sprachkurse und Integrationsprogramme, um eine reibungslose
            Eingliederung in Ihr Arbeitsumfeld zu gewährleisten.
          </Paragraph>
          <Paragraph>
            Bei GermanyJobsNow beantworten wir gerne Ihre Fragen zu
            Möglichkeiten geeignete Bewerber aus dem EU und Nicht EU Ausland für
            ihr Unternehmen zu finden.{" "}
          </Paragraph>
          <Paragraph>
            Wir informieren Sie dabei, wie Sie das neue
            Fachkräfteeinwanderungsgesetzt bei Personalproblemen für Ihr
            Unternehmen nützen können. Unser Fokus liegt darauf, Jobangebote für
            internationale Fachkräfte zu erstellen und Ihnen bei der
            Rekrutierung ausländischer Fachkräfte zu unterstützen. Wir bieten
            eine umfassende Beratung und begleiten Sie während des gesamten
            Prozesses, von der Identifizierung des Bedarfs bis hin zur
            erfolgreichen Integration der ausländischen Mitarbeiter in Ihr
            Unternehmen.{" "}
          </Paragraph>
          <Paragraph>
            Als Experten für Personalvermittlung und International Recruiting
            verfügen wir über ein globales Netzwerk und ein tiefgehendes
            Verständnis des internationalen Arbeitsmarkts. Durch unsere
            vielfältigen Kooperationen und Partnerschaften mit internationalen
            Fachkräftenetzwerken können wir Ihnen helfen, internationale Talente
            für Ihr Unternehmen zu gewinnen.{" "}
          </Paragraph>
        </Section>
        <Section>
          <Paragraph>
            Im Folgenden finden Sie Informationen zu den verschiedenen Schritten
            unseres Vermittlungsprozesses:{" "}
          </Paragraph>
        </Section>
        <Section>
          <Paragraph>
            1. Bedarfsanalyse: Wir beginnen den Vermittlungsprozess mit einer
            gründlichen Bedarfsanalyse. Dabei arbeiten wir eng mit Ihnen
            zusammen, um ein genaues Verständnis Ihrer Anforderungen,
            Bedürfnisse und Ziele zu entwickeln. Wir berücksichtigen Ihre
            spezifischen Branchenkenntnisse, Unternehmenskultur und die Art der
            Fachkräfte, die Sie suchen.{" "}
          </Paragraph>
          <Paragraph>
            2. Abgleich zwischen Anforderungs- und Kandidatenprofil: Anhand der
            gesammelten Informationen erstellen wir ein detailliertes
            Anforderungsprofil für die zu besetzende Stelle. Wir gleichen dieses
            Profil mit unserem Kandidatenpool ab, um potenzielle Kandidaten zu
            identifizieren, die Ihren Anforderungen entsprechen. Wir legen
            großen Wert auf eine sorgfältige Vorauswahl, um Ihnen nur die
            qualifiziertesten Kandidaten vorzustellen.{" "}
          </Paragraph>
          <Paragraph>
            3. Datenbankanalyse: Unsere umfangreiche Datenbank umfasst eine
            Vielzahl von Fachkräften aus verschiedenen Ländern und Branchen. Wir
            führen eine gründliche Analyse unserer Datenbank durch, um geeignete
            Kandidaten zu identifizieren, die Ihren Anforderungen entsprechen.
            Dabei berücksichtigen wir neben den fachlichen Qualifikationen auch
            Sprachkenntnisse, Arbeitserfahrung und weitere relevante Faktoren.{" "}
          </Paragraph>
          <Paragraph>
            4. Kandidatenauswahl: Nachdem wir potenzielle Kandidaten
            identifiziert haben, führen wir eine weitere Bewertung durch. Wir
            prüfen ihre Qualifikationen, überprüfen Referenzen und führen
            Interviews, um ihr Fachwissen und ihre Fähigkeiten besser
            einschätzen zu können. Dadurch stellen wir sicher, dass nur die
            vielversprechendsten Kandidaten für Sie ausgewählt werden.{" "}
          </Paragraph>
          <Paragraph>
            5. Präsentation der Kandidaten: Wir präsentieren Ihnen eine Auswahl
            der besten Kandidaten, die Ihren Anforderungen entsprechen. Dabei
            stellen wir Ihnen umfassende Informationen über ihre
            Qualifikationen, beruflichen Hintergrund und ihre bisherigen
            Erfahrungen zur Verfügung. Wir unterstützen Sie auch bei der Planung
            und Organisation von Vorstellungsgesprächen und stehen Ihnen bei
            Fragen oder zusätzlichen Informationen jederzeit zur Verfügung.{" "}
          </Paragraph>
          <Paragraph>
            6. Begleitung der Personalvermittlung: Wir begleiten Sie während des
            gesamten Vermittlungsprozesses. Wir unterstützen Sie bei
            Vertragsverhandlungen, der Erstellung von Arbeitsverträgen und bei
            Fragen zur Visa- und Einwanderungsprozessen. Unsere Dienstleistungen
            enden nicht mit der erfolgreichen Vermittlung eines Kandidaten. Wir
            sind auch nach der Einstellung für Sie da und stehen Ihnen bei
            Bedarf zur Verfügung.{" "}
          </Paragraph>
        </Section>
        <Section>
          <Paragraph>
            Unser Ziel ist es, Ihnen bei der Gewinnung hochqualifizierter
            Fachkräfte aus dem Ausland zu helfen und Ihnen einen reibungslosen
            Vermittlungsprozess zu bieten. Kontaktieren Sie uns gerne, um
            weitere Informationen zu erhalten oder um mit uns über Ihre
            spezifischen Anforderungen zu sprechen. Wir freuen uns darauf, Ihnen
            zu helfen.{" "}
          </Paragraph>
          <Paragraph>
            Die Beschäftigung ausländischer Fachkräfte bietet zahlreiche
            Vorteile für Ihr Unternehmen. Neben der Deckung Ihres
            Fachkräftebedarfs bringen internationale Talente oft frische
            Perspektiven, interkulturelle Kompetenzen und einzigartiges
            Fachwissen mit. Wir helfen Ihnen, diese Vorteile voll auszuschöpfen
            und eine integrative und diverse Arbeitsumgebung zu schaffen, in der
            alle Mitarbeiter ihr volles Potenzial entfalten können.{" "}
          </Paragraph>
          <Paragraph>
            Unsere Personalvermittlung bietet maßgeschneiderte Lösungen für
            verschiedene Branchen und unterstützt Sie bei der Gewinnung und
            Bindung von ausländischen Talenten. Wir bieten internationale
            Personalbeschaffung, Global Talent Acquisition und helfen Ihnen,
            Ihre Employer-Branding-Strategie für internationale Märkte zu
            optimieren.{" "}
          </Paragraph>
          <Paragraph>
            Wir organisieren Jobmessen und Karrieremessen im Ausland, um
            potenzielle Kandidaten zu treffen und Ihr Unternehmen als
            attraktiven Arbeitgeber zu präsentieren. Zudem bauen wir
            Partnerschaften mit ausländischen Bildungseinrichtungen auf, um den
            Zugang zu qualifizierten Fachkräften zu erleichtern.{" "}
          </Paragraph>
          <Paragraph>
            In einer zunehmend digitalisierten Welt nutzen wir die Chancen der
            Technologie, um Remote-Recruiting und virtuelles Onboarding für
            internationale Fachkräfte anzubieten. Unser globales Mobility
            Management unterstützt Mitarbeiter bei internationalen Einsätzen und
            ermöglicht eine nahtlose Zusammenarbeit über Grenzen hinweg.{" "}
          </Paragraph>
          <Paragraph>
            Unsere interkulturellen Trainings und Mentoring-Programme fördern
            die erfolgreiche kulturelle Anpassung und Zusammenarbeit von
            ausländischen Fachkräften in Ihrem Unternehmen. Wir helfen Ihnen
            auch bei der Anerkennung ausländischer Abschlüsse und bieten
            Unterstützung bei der Weiterentwicklung und Integration Ihrer
            Mitarbeiter.{" "}
          </Paragraph>
          <Paragraph>
            Mit unserer Arbeitsmarktanalyse für ausländische Fachkräfte und
            unserem globalen Arbeitsmarktüberblick erhalten Sie fundierte
            Informationen, um Ihre Personalentscheidungen zu optimieren und den
            Fachkräftemangel zu bewältigen.{" "}
          </Paragraph>
          <Paragraph>
            Die Gewinnung und Bindung von internationalen Fachkräften erfordert
            eine ganzheitliche Talentmanagementstrategie. Wir helfen Ihnen,
            diverse Belegschaften aufzubauen, Fachkräfte zu binden und zu
            entwickeln. Dabei berücksichtigen wir internationale
            Gehaltsstrukturen und bieten Vergütungspakete, die den Bedürfnissen
            Ihrer ausländischen Talente gerecht werden.{" "}
          </Paragraph>
          <Paragraph>
            Die Zusammenarbeit in internationalen Teams birgt Herausforderungen,
            aber auch große Potenziale. Wir unterstützen Sie dabei,
            interkulturelle Unterschiede zu managen und eine integrative
            Arbeitsumgebung zu schaffen, in der alle Mitarbeiter gleiche Chancen
            haben und ihre Stärken einbringen können.{" "}
          </Paragraph>
          <Paragraph>
            Durch unsere Kooperationen mit Migrations- und Integrationsbehörden
            sowie internationalen Netzwerken können wir Ihnen zusätzliche
            Unterstützung bieten, um den Einsatz von ausländischen Fachkräften
            reibungslos zu gestalten.{" "}
          </Paragraph>
          <Paragraph>
            Steigern Sie Ihre Wettbewerbsfähigkeit und nutzen Sie das Potenzial
            des globalen Arbeitskräftemarktes. Unsere Experten von
            GermanyJobsNow für ausländische Fachkräfte stehen Ihnen zur Seite,
            um Ihre Rekrutierungsziele zu erreichen und eine erfolgreiche
            Integration von internationalen Talenten in Ihr Unternehmen zu
            gewährleisten.{" "}
          </Paragraph>
          <Paragraph>
            Um Unternehmen passende Kandidaten vorschlagen zu können, benötigen
            wir einige Informationen von Ihnen. Bitte füllen Sie das folgende
            Formular aus:{" "}
          </Paragraph>
          <Paragraph>
            Bitte füllen Sie das Formular vollständig aus, damit wir Ihre
            Anforderungen besser verstehen und Ihnen geeignete Kandidaten
            vorschlagen können. Sobald wir Ihre Informationen erhalten haben,
            werden wir uns umgehend mit Ihnen in Verbindung setzen, um den
            weiteren Vermittlungsprozess zu besprechen. Vielen Dank für Ihr
            Vertrauen!{" "}
          </Paragraph>
        </Section>
        <PrimaryButton as="a" href="company-contact" css={buttonRoundedCss}>
          kontaktiere uns
        </PrimaryButton>
      </Container>
    </Content>

    <Footer />
  </AnimationRevealPage>
);
